<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      spinner="bar-fade-scale"
      color="#1976d2"
      size="128"
      is-full-screen
    />
    <v-snackbar v-model="showsnackbar" color="black" right>
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showsnackbar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout v-if="ServerError" wrap justify-center>
      <v-flex px-5 pt-5 xs12 sm12 md12 lg12 xl12 text-center>
        <ServerError />
      </v-flex>
    </v-layout>
    <v-layout v-else wrap justify-center>
      <v-flex px-5 pb-5 xs12 sm12 md12 lg12 xl12>
        <v-card class="pa-4">
          <v-card-title class="">
            Discover User Details
            <v-spacer></v-spacer>
          </v-card-title>
          <div v-if="user">
            <v-row
              class="py-0 justify-content-md-center"
              style="background-color: #b0a7a8"
            >
              <v-col cols="12" class="pa-6">
                <v-row>
                  <v-col cols="12" sm="2" md="2" lg="1">
                    <div v-viewer>
                      <v-avatar
                        style="cursor: pointer"
                        v-if="user.photo"
                        size="120"
                      >
                        <img :src="baseURL + user.photo" />
                      </v-avatar>
                    </div>
                  </v-col>
                  <v-col cols="12" md="10" sm="10" lg="11" class="pa-10" style="">
                    <h1 style="color: red">
                      {{ user.name }}
                    </h1>
                    <h5 style="color: red">{{ user.email }}</h5>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

            <v-row class="pt-0">
              <v-col cols="12" sm="6" md="4">
                <v-row>
                  <v-col cols="5">
                    <span class="item_title"> Name</span>
                  </v-col>
                  <v-col cols="7">
                    <span class="item_value">-</span>
                    <span class="item_value ma-2">{{ user.name }}</span>
                  </v-col>
                </v-row>
                <hr />
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-row>
                  <v-col cols="5">
                    <span class="item_title">Phone</span>
                  </v-col>
                  <v-col cols="7">
                    <span class="item_value">-</span>
                    <span class="item_value ma-2">{{ user.phone }}</span>
                  </v-col>
                </v-row>
                <hr />
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-row>
                  <v-col cols="4" md="5">
                    <span class="item_title">Email</span>
                  </v-col>
                  <v-col cols="8" md="7">
                    <span class="item_value">-</span>
                    <span class="item_value ma-2">{{ user.email }}</span>
                  </v-col>
                </v-row>
                <hr />
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-row>
                  <v-col cols="4" md="5">
                    <span class="item_title">Profession</span>
                  </v-col>
                  <v-col cols="8" md="7">
                    <span class="item_value">-</span>
                    <span class="item_value ma-2">{{ user.profession }}</span>
                  </v-col>
                </v-row>
                <hr />
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-row>
                  <v-col cols="5">
                    <span class="item_title">Date of Birth</span>
                  </v-col>
                  <v-col cols="7">
                    <span class="item_value">-</span>
                    <span class="item_value ma-2"
                      >{{ formatDate(user.dob) }}
                    </span>
                  </v-col>
                </v-row>
                <hr />
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-row>
                  <v-col cols="5">
                    <span class="item_title">Height</span>
                  </v-col>
                  <v-col cols="7" v-if="user.height">
                    <span class="item_value">-</span>
                    <span class="item_value ma-2"
                      >{{ user.height }}
                    </span>
                  </v-col>
                </v-row>
                <hr />
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-row>
                  <v-col cols="5">
                    <span class="item_title">Weight</span>
                  </v-col>
                  <v-col cols="7" v-if="user.weight">
                    <span class="item_value">-</span>
                    <span class="item_value ma-2"
                      >{{ user.weight }}
                    </span>
                  </v-col>
                </v-row>
                <hr />
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-row>
                  <v-col cols="5">
                    <span class="item_title">Gender</span>
                  </v-col>
                  <v-col cols="7" v-if="user.gender">
                    <span class="item_value">-</span>
                    <span class="item_value ma-2"
                      >{{ user.gender }}
                    </span>
                  </v-col>
                </v-row>
                <hr />
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-row>
                  <v-col cols="5">
                    <span class="item_title">Body Size</span>
                  </v-col>
                  <v-col cols="7">
                    <span class="item_value">-</span>
                    <span class="item_value ma-2">{{ user.bodySize }} </span>
                  </v-col>
                </v-row>
                <hr />
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-row>
                  <v-col cols="5">
                    <span class="item_title">Embellishment</span>
                  </v-col>
                  <v-col cols="7" v-if="user.embellishment.length>0">
                    <span class="item_value">-</span>
                    <span class="item_value ma-2" v-for="(emb, e) in user.embellishment" :key="e"
                      >{{ emb }},
                    </span>
                  </v-col>
                  <v-col cols="7" v-else>
                    <span class="item_value">-</span>
                    <span class="item_value ma-2"
                      >{{ user.embellishment }}
                    </span>
                  </v-col>
                </v-row>
                <hr />
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-row>
                  <v-col cols="5">
                    <span class="item_title">Fabric</span>
                  </v-col>
                  <v-col cols="7" v-if="user.fabric.length>0">
                    <span class="item_value">-</span>
                    <span class="item_value ma-2" v-for="(fab, f) in user.fabric" :key="f">{{ fab }}, </span>
                  </v-col>
                  <v-col cols="7" v-else>
                    <span class="item_value">-</span>
                    <span class="item_value ma-2">{{  user.fabric }} </span>
                  </v-col>
                </v-row>
                <hr />
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-row>
                  <v-col cols="4" md="5">
                    <span class="item_title">Style Preference</span>
                  </v-col>
                  <v-col cols="8" md="7">
                    <span class="item_value">-</span>
                    <span class="item_value ma-2">{{
                      user.stylePreference
                    }}</span>
                  </v-col>
                </v-row>
                <hr />
              </v-col>
              <v-col cols="12" sm="12" md="12" v-if="user.note">
                <v-row>
                  <v-col cols="4" md="3" lg="1">
                    <span class="item_title">Note</span>
                  </v-col>
                  <v-col cols="8" md="9" lg="11">
                    <span class="item_value">-</span>
                    <span class="item_value ma-2">{{ user.note }}</span>
                  </v-col>
                </v-row>
                <hr />
              </v-col>
              <v-row class="pt-0 pa-2">

              <v-col cols="12" sm="6" md="4">
                <v-row>
                  <v-col cols="12">
                    <span class="item_title">Body Type</span>
                  </v-col>
                  <v-col cols="3" class="text-left pa-0">
                    <v-img
                      width="auto"
                      height="150px"
                      contain
                      :src="mediaURL + user.bodyType"
                    ></v-img>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-row>
                  <v-col cols="12">
                    <span class="item_title">Color Combination</span>
                  </v-col>
                  <v-col cols="5" class="text-left">
                    <v-img
                      width="auto"
                      height="100%"
                      contain
                      :src="mediaURL + user.colorCombination"
                    ></v-img>
                  </v-col>
                </v-row>
              </v-col>
              </v-row>

            </v-row>
          </div>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      msg: null,
      ServerError: false,
      showsnackbar: false,
      user: [],
      appLoading: false,
      dialog: false,
      userStatus: null,
      userRole: localStorage.getItem("role"),
      product: [],
      photos: [],
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    winStepper(winStep) {
      if (winStep.showsnackbar) {
        this.showsnackbar = true;
        this.msg = winStep.msg;
      }
    },
    getData() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/shopbylook/discover/enquiry/get",
        params: {
          id: this.$route.query.userId,
        },
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.user = response.data.data;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    formatDate(item) {
      var dt = new Date(item);
      var day = dt.getDate();
      var year = dt.getFullYear();
      var hours = dt.getHours();
      var minutes = dt.getMinutes();
      dt = dt.toString();
      var ampm = hours >= 12 ? "pm" : "am";
      hours = hours % 12;
      hours = hours ? hours : 12;
      minutes = minutes < 10 ? "0" + minutes : minutes;
      var strTime =
        day +
        " " +
        dt.slice(4, 7) +
        " " +
        year +
        " " +
        hours +
        ":" +
        minutes +
        " " +
        ampm;
      return strTime;
    },
  },
};
</script>
<style scoped>
.item_title {
  font-weight: bold;
  font-size: 14px;
}
.item_value {
  font-size: 13px;
}
</style>
